<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.29294 16.7082L4.29294 12.7132C4.12061 12.5411 4.017 12.3121 4.00153 12.069C3.98607 11.826 4.05981 11.5857 4.20894 11.3932L4.29194 11.2992L8.29194 7.29316C8.471 7.10978 8.71364 7.00216 8.96976 6.9925C9.22589 6.98285 9.47594 7.07191 9.66829 7.2413C9.86065 7.41069 9.98062 7.64746 10.0034 7.90275C10.0263 8.15804 9.9502 8.41234 9.79094 8.61316L9.70794 8.70716L7.41694 11.0002L19.0009 11.0002C19.2459 11.0002 19.4823 11.0901 19.6653 11.2529C19.8483 11.4156 19.9653 11.6399 19.9939 11.8832L19.9999 12.0012C19.9999 12.2462 19.9098 12.4828 19.7468 12.6658C19.5839 12.8489 19.3594 12.9657 19.1159 12.9942L19.0009 13.0012L7.40994 13.0012L9.70694 15.2942C9.87926 15.4662 9.98288 15.6953 9.99834 15.9383C10.0138 16.1813 9.94006 16.4216 9.79094 16.6142L9.70694 16.7082C9.53487 16.8805 9.30584 16.9841 9.06281 16.9996C8.81978 17.015 8.57946 16.9413 8.38694 16.7922L8.29294 16.7082L4.29294 12.7132L8.29294 16.7082Z"
      fill="#6F6F6F"
    />
  </svg>
</template>

<script>
export default {};
</script>