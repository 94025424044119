<template>
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 5.99974H5.101L5.102 5.99074C5.23257 5.35138 5.48813 4.74409 5.854 4.20374C6.39845 3.40156 7.16215 2.7729 8.054 2.39274C8.356 2.26474 8.671 2.16674 8.992 2.10174C9.65789 1.96674 10.3441 1.96674 11.01 2.10174C11.967 2.29784 12.8451 2.77116 13.535 3.46274L14.951 2.05074C14.3128 1.41238 13.5578 0.902783 12.727 0.549742C12.3033 0.370371 11.8628 0.233695 11.412 0.141742C10.4818 -0.0472473 9.52316 -0.0472473 8.593 0.141742C8.14185 0.234076 7.70101 0.371085 7.277 0.550742C6.02753 1.08084 4.95793 1.96083 4.197 3.08474C3.68489 3.84259 3.32676 4.69373 3.143 5.58974C3.115 5.72474 3.1 5.86274 3.08 5.99974H0L4 9.99974L8 5.99974ZM12 7.99974H14.899L14.898 8.00774C14.6367 9.28951 13.8812 10.4168 12.795 11.1457C12.2548 11.5119 11.6475 11.7675 11.008 11.8977C10.3424 12.0327 9.65656 12.0327 8.991 11.8977C8.35163 11.7672 7.74435 11.5116 7.204 11.1457C6.93862 10.9662 6.69085 10.762 6.464 10.5357L5.05 11.9497C5.68851 12.5879 6.44392 13.0972 7.275 13.4497C7.699 13.6297 8.142 13.7667 8.59 13.8577C9.51982 14.0468 10.4782 14.0468 11.408 13.8577C13.2005 13.4857 14.7773 12.4292 15.803 10.9127C16.3146 10.1555 16.6724 9.30501 16.856 8.40974C16.883 8.27474 16.899 8.13674 16.919 7.99974H20L16 3.99974L12 7.99974Z"
      fill="#6F6F6F"
    />
  </svg>
</template>

<script>
export default {
  name: "ReloadIcon",
};
</script>