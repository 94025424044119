<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1059_181)">
      <path
        d="M6.97399 1.57812C4.25435 1.57812 2.04785 3.68915 2.04785 6.2911C2.04785 8.89306 4.25435 11.0041 6.97399 11.0041C9.69363 11.0041 11.9001 8.89306 11.9001 6.2911C11.9001 3.68915 9.69363 1.57812 6.97399 1.57812Z"
        stroke="#6F6F6F"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M6.97412 3.14844V6.68317H9.43719"
        stroke="#6F6F6F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1059_181">
        <rect
          width="13.1364"
          height="12.5679"
          fill="white"
          transform="translate(0.405762 0.0078125)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "BoxIcon",
};
</script>