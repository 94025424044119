<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.5"
      transform="rotate(90 11 11)"
      fill="#A01B5B"
      stroke="#A01B5B"
    />
    <path
      d="M11.243 11.171L8.415 14L7 12.586L11.243 8.343L15.486 12.586L14.071 14L11.243 11.171Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownIcon",
  props: {
    color: {
      type: String,
      default: "#A01B5B",
    },
  },
};
</script>