<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="10.5"
      transform="rotate(90 11 11)"
      fill="#E4E4E4"
      stroke="#E4E4E4"
    />
    <path
      d="M10.9998 13.9998L6.75684 9.75677L8.17184 8.34277L10.9998 11.1718L13.8278 8.34277L15.2428 9.75677L10.9998 13.9998Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDownIcon",
  props: {
    color: {
      type: String,
      default: "#A01B5B",
    },
  },
};
</script>