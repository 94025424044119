<template>
  <span>
    {{ shownText }}
    <br />
    <div class="mt-3" v-if="textIsLong">
      <a @click="toggleShowMore">{{ showFull ? "Свернуть" : "Показать весь текст" }}</a>
    </div>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      type: [String, Number],
      default: "",
    },
  },
  data: () => ({
    showFull: false,
    cutLength: 150,
  }),
  computed: {
    strippedFromHtmlText() {
      return this.stripHTML(this.text.toString());
    },
    textIsLong() {
      return this.strippedFromHtmlText.length > this.cutLength;
    },
    shownText() {
      if (this.showFull || !this.textIsLong) return this.strippedFromHtmlText;
      return this.strippedFromHtmlText.slice(0, this.cutLength);
    },
  },
  methods: {
    toggleShowMore() {
      this.showFull = !this.showFull;
    },
  },
};
</script>

<style lang="scss">
</style>